<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/punchRecord">punchRecord管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label prop="id">
        <el-input type="text" size="mini" v-model="queryModel.id"></el-input>
      </el-form-item>
      <el-form-item label="打卡人ID" prop="personId">
        <el-input type="text" size="mini" v-model="queryModel.personId"></el-input>
      </el-form-item>
      <el-form-item label="打卡点ID" prop="punchId">
        <el-input type="text" size="mini" v-model="queryModel.punchId"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input type="text" size="mini" v-model="queryModel.remark"></el-input>
      </el-form-item>
      <el-form-item label="当前经度" prop="longtitude">
        <el-input type="text" size="mini" v-model="queryModel.longtitude"></el-input>
      </el-form-item>
      <el-form-item label="当前维度" prop="latitude">
        <el-input type="text" size="mini" v-model="queryModel.latitude"></el-input>
      </el-form-item>
      <el-form-item label="是否删除" prop="delFlag">
        <el-input type="text" size="mini" v-model="queryModel.delFlag"></el-input>
      </el-form-item>
      <el-form-item label="创建人" prop="createBy">
        <el-input type="text" size="mini" v-model="queryModel.createBy"></el-input>
      </el-form-item>
      <el-form-item label="创建时间" prop="createTime">
        <el-input type="text" size="mini" v-model="queryModel.createTime"></el-input>
      </el-form-item>
      <el-form-item label="更新人" prop="updateBy">
        <el-input type="text" size="mini" v-model="queryModel.updateBy"></el-input>
      </el-form-item>
      <el-form-item label="更新时间" prop="updateTime">
        <el-input type="text" size="mini" v-model="queryModel.updateTime"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button type="primary" size="small" plain icon="el-icon-circle-plus" @click="handleAdd">新增</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length==0"
        @click="handleBatchDelete"
      >删除选中项</el-button>
    </el-row>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" sort-by="id_" label sortable="custom" width="180"></el-table-column>
      <el-table-column
        prop="personId"
        sort-by="person_id"
        label="打卡人ID"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="punchId"
        sort-by="punch_id"
        label="打卡点ID"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column prop="remark" sort-by="remark_" label="备注" sortable="custom" width="180"></el-table-column>
      <el-table-column
        prop="longtitude"
        sort-by="longtitude_"
        label="当前经度"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="latitude"
        sort-by="latitude_"
        label="当前维度"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column prop="delFlag" sort-by="del_flag" label="是否删除" sortable="custom" width="180"></el-table-column>
      <el-table-column
        prop="createBy"
        sort-by="create_by"
        label="创建人"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        sort-by="create_time"
        label="创建时间"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="updateBy"
        sort-by="update_by"
        label="更新人"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="updateTime"
        sort-by="update_time"
        label="更新时间"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{row}">
          <el-button size="mini" type="warning" @click="handleEdit(row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
</template>
<script>
import Constant from "@/constant";
import punchRecordApi from "@/api/base/punchRecord";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "BasePunchRecordList",
  data() {
    var self = this;

    return {
      queryModel: {
        id: "",
        personId: "",
        punchId: "",
        remark: "",
        longtitude: "",
        latitude: "",
        delFlag: "",
        createBy: "",
        createTime: "",
        updateBy: "",
        updateTime: ""
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: ""
    };
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("id", self.queryModel.id);
      formData.append("personId", self.queryModel.personId);
      formData.append("punchId", self.queryModel.punchId);
      formData.append("remark", self.queryModel.remark);
      formData.append("longtitude", self.queryModel.longtitude);
      formData.append("latitude", self.queryModel.latitude);
      formData.append("delFlag", self.queryModel.delFlag);
      formData.append("createBy", self.queryModel.createBy);
      formData.append("createTime", self.queryModel.createTime);
      formData.append("updateBy", self.queryModel.updateBy);
      formData.append("updateTime", self.queryModel.updateTime);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      punchRecordApi
        .pageList(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          punchRecordApi.remove(record.id).then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!"
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map(record => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        punchRecordApi.batchRemove(idList).then(function(response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    }
  },
  mounted: function() {
    this.changePage(1);
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>